// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import ThemeSwitchMode from "./I_1K6zwMv";
const ThemeSwitchModeFonts = getFonts(ThemeSwitchMode);

const cycleOrder = ["Z6eyp0qJa"];

const serializationHash = "framer-P9wUb"

const variantClassNames = {Z6eyp0qJa: "framer-v-173dxy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, iconColor, id, width, ...props}) => { return {...props, Tib6Q7ZnQ: iconColor ?? props.Tib6Q7ZnQ ?? "var(--token-fa6255fa-1747-4bc3-894a-8375bc25f0bd, rgb(28, 28, 28))"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;iconColor?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Tib6Q7ZnQ, ixZIJysro, d0DRJtbRf, LTl4MmErQ, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Z6eyp0qJa", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-173dxy", className, classNames)} data-framer-name={"ThemeSwitch"} layoutDependency={layoutDependency} layoutId={"Z6eyp0qJa"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-i8apbi-container"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"uhIFjhr5o-container"} name={"Icon"}><ThemeSwitchMode AeO5PLd9q={Tib6Q7ZnQ} fle84VlOx={LTl4MmErQ} height={"100%"} id={"uhIFjhr5o"} layoutId={"uhIFjhr5o"} name={"Icon"} njtQOvFbC={ixZIJysro} pdO6Nm4lI={d0DRJtbRf} variant={"uYE_do7F1"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-P9wUb.framer-1xx2uch, .framer-P9wUb .framer-1xx2uch { display: block; }", ".framer-P9wUb.framer-173dxy { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; padding: 0px; position: relative; width: min-content; }", ".framer-P9wUb .framer-i8apbi-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-P9wUb.framer-173dxy { gap: 0px; } .framer-P9wUb.framer-173dxy > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-P9wUb.framer-173dxy > :first-child { margin-left: 0px; } .framer-P9wUb.framer-173dxy > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"Tib6Q7ZnQ":"iconColor"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameroQCFNSYDH: React.ComponentType<Props> = withCSS(Component, css, "framer-P9wUb") as typeof Component;
export default FrameroQCFNSYDH;

FrameroQCFNSYDH.displayName = "ThemeSwitch";

FrameroQCFNSYDH.defaultProps = {height: 27, width: 24};

addPropertyControls(FrameroQCFNSYDH, {Tib6Q7ZnQ: {defaultValue: "var(--token-fa6255fa-1747-4bc3-894a-8375bc25f0bd, rgb(28, 28, 28)) /* {\"name\":\"font/active\"} */", title: "IconColor", type: ControlType.Color}} as any)

addFonts(FrameroQCFNSYDH, [{explicitInter: true, fonts: []}, ...ThemeSwitchModeFonts], {supportsExplicitInterCodegen: true})